import i18n from '../../../i18n'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

export const DriverRow = ({
  driver,
  rowNumber,
  onShowDriverLocation,
  onShowDriverDetails
}) => {

  const auth = useSelector(state => state.auth)
  const myself = (auth.user && driver.id === auth.user.id)

  return <tr className={(myself ? " disabled2" : "highlight")} style={{
      cursor: "pointer",

  }} onClick={() => onShowDriverDetails(driver)} key={driver.id}>
      <th scope="row">{rowNumber}</th>
      <td>{driver.name}</td>
      <td>{driver.email}</td>
      <td>{driver.phone}</td>
      <td>
          <div style={{
              display: 'flex',
              gap: 10
          }}>
              <div style={{
                  backgroundColor: driver.online ? 'green' : 'red',
                  height: 20,
                  width: 20,
                  borderRadius: '100%'
              }} />
              {dayjs(driver.lastonline).format("DD/MM/YYYY HH:mm")}
          </div>
      </td>
      <td>{driver.currentlocation && <button onClick={(e) => {
         e.preventDefault()
         e.stopPropagation()
          onShowDriverLocation(driver)
      }} className="btn btn-success">{i18n.t("check-location")}</button>}</td>
  </tr>
}
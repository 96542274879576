import i18n from "../../i18n";
import {
  GoogleMap,
  Marker,
  useJsApiLoader
} from '@react-google-maps/api'

const mapContainerStyle = {
  width: '100%',
  height: '100%'
}
const options = {
  disableDefaultUI: true,
  zoomControl: true
}

export const DriverLocation = ({
  closeLocationModal,
  center,
  driverLocation,
  closeModal,
}) => {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })
  return (
    <div className="modal " id="driverLocation" tabIndex={-1}>
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{i18n.t("driver-location")}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => closeLocationModal()}
              aria-label="Close"
            ></button>
          </div>
          <div
            className="modal-body"
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
            >
              {driverLocation && isLoaded && (
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  zoom={15}
                  center={center}
                  onLoad={(map) => {
                    //mapRef.current = map
                  }}
                  options={options}
                >
                  {driverLocation ? (
                    <Marker
                      position={{
                        lat: driverLocation.latitude,
                        lng: driverLocation.longitude,
                      }}
                      onIconChanged={() => console.log("onIconChangd")}
                      icon={{
                        url: `/images/driver/driver-marker-${Math.abs(
                          Math.floor(driverLocation.heading)
                        )}.png`,
                      }}
                    //icon="/images/driver-marker.png"
                    />
                  ) : null}
                </GoogleMap>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
              data-bs-dismiss="modal"
            >
              {i18n.t("close")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useEffect, useRef } from "react";
import io from "socket.io-client"

export const useSocket = () => {
  const socketRef = useRef();

  useEffect(() => {
    console.log('connecting to socket', process.env.REACT_APP_SOCKET_URL);
    socketRef.current = io(process.env.REACT_APP_SOCKET_URL);
    socketRef.current.on('connect', () => {
      console.log('now im connected')
    })
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    }
  }, []);
  return socketRef.current;
}
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import i18n from '../../i18n'
import { driverApi } from '../../utils/api'


import { useSocket } from '../../hooks/useSocket'
import { DriverLocation } from './DriverLocation'
import { DriverRow } from './components/DriverRow'


let addDriverModal = null
let driverLocationModal = null
let getDriverTimeout = null

const Drivers = () => {

    const auth = useSelector(state => state.auth)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [phone, setPhone] = useState("")
    const [selectedBusineses, setSelectedBusineses] = useState([])
    const [businesses, setBusinesses] = useState([])
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [editUser, setEditUser] = useState(false)
    const history = useHistory()
    const [addingDriver, setAddingDriver] = useState(false)
    const [drivers, setDrivers] = useState([])
    const [getAllOrders, setGetAllOrders] = useState(false)
    const [driverLocation, setDriverLocation] = useState(null)
    const [center, setCenter] = useState({
        lat: 32.085543403635036,
        lng: 34.780881135951304
    })
    const socketClient = useSocket();

    useEffect(() => {
        console.log('socketClient', socketClient)
        if(socketClient){
            socketClient.on('driversOnline', (data) => {
                console.log('driversOnline', data)
                //When we get the driversOnline event, we want to fetch the drivers again
                // we need to wait 2 seconds to make sure the driver is online
                setTimeout(getDrivers, 2000);
            });
        }
    }, [socketClient]);

    const addDriver = async () => {
        console.log("addDriver", editUser)
        setAddingDriver(true)
        if (editUser) {
            let params = {

                phone,
                name,
                businesses: selectedBusineses,
                allOrders: getAllOrders
            }

            try {

                if (password) {
                    await driverApi.put("/users/driver/" + editUser, { password })
                }

                await driverApi.put("/drivers/" + editUser, params)
                closeModal()
                getDrivers();
            }
            catch (e) {
                console.error('Could update user', e);

            }
            setAddingDriver(false)

        } else {

            let result = await driverApi.post("/drivers", {
                name,
                phone,
                businesses: selectedBusineses,
                confirmed: true,
                email,
                driverManager: false,
                driverManagerAdmin: false,
                allOrders: getAllOrders

            })

            let driver = result.data

            await driverApi.post("/auth/local/register", {
                username: username,
                email,
                password: password,
                confirmed: true,
                driver: driver.id,
                driverManager: false,
                driverManagerAdmin: false,

            });

            setAddingDriver(false)
            console.log("myModal", addDriverModal)
            closeModal()
            getDrivers();
        }

        // .catch((e) => {
        //     alert(i18n.t("failed-creating-driver"))
        //     console.warn("could not add driver", e);
        // })
    }
    const getDrivers = () => {

        let driverBusinesses = []
        let filterParams = ""
        auth.user?.businesses.forEach((bussiness) => {
            driverBusinesses.push(bussiness.id)
            if (filterParams) {
                filterParams += "&"
            }
            filterParams += "businesses_in=" + bussiness.id
        })

        let businessFilter = auth.user?.driverManagerAdmin ? "" : "?" + filterParams
        driverApi.get("/drivers" + businessFilter)
            .then(({ data }) => {
                setDrivers(data)
            })
            .catch((e) => {
                console.warn("/drivers warn", e)
            })
    }
    const getBusinesses = () => {
        if (auth.user.driverManagerAdmin)
            driverApi.get("/businesses")
                .then(({ data }) => {
                    setBusinesses(data)
                })
                .catch((e) => {
                    console.warn("/businesses warn", e)
                })
        else {
            let userBusinesses = []
            auth.user.businesses.forEach((bussiness) => {
                userBusinesses.push(bussiness)
            })
            setBusinesses(userBusinesses);
        }

    }


    const onSelectedBusinesses = (e) => {
        // console.log("onBusinesses", e.target.selectedOptions)
        let businesses = []
        Array.from(e.target.selectedOptions).forEach((option) => {
            if (option.selected)
                businesses.push(option.value)
        })
        setSelectedBusineses(businesses)
    }

    const onDelete = () => {
        if (window.confirm(i18n.t("confirm-delete"))) {
            driverApi.delete("/drivers/" + editUser)
                .then(({ data }) => {
                    closeModal()
                    getDrivers();
                })
                .catch((e) => {
                    console.warn("could not delete", e)
                })
        }
    }

    const closeLocationModal = () => {
        setDriverLocation(null)
        driverLocationModal.hide()

    }

    const onShowDriverDetails = (driver) => {


        if (driver.user) {
            setUsername(driver.user.username)
        }
        setEmail(driver.email)
        setPhone(driver.phone)
        setName(driver.name)
        setGetAllOrders(driver.allOrders)
        setPassword("")
        let businesses = []
        driver.businesses.forEach((business) => {
            businesses.push(business.id)
        })
        setSelectedBusineses(businesses)
        console.log("driver", driver.id)
        setEditUser(driver.id)

    }

    const onShowDriverLocation = (driver) => {

        console.log("currentlocation", driver.currentlocation)
        setDriverLocation(driver.currentlocation)
        setCenter({
            lat: driver.currentlocation.latitude,
            lng: driver.currentlocation.longitude
        })
        driverLocationModal.show();

    }

    const closeModal = () => {
        console.log('closeModal')
        setPassword("")
        setEmail("")
        setPhone("")
        setName("")
        setUsername("")
        setGetAllOrders(false)
        setSelectedBusineses([])
        setEditUser(false)
        setAddingDriver(false)
        addDriverModal.hide()
    }

    useEffect(() => {
        if (auth.user && !auth.user.driverManager) {

            return history.replace("/")
        }

    }, [auth.user, history])

    useEffect(() => {

        if (getAllOrders) {
            let selectAllBusiness = []
            auth.user.businesses.forEach((buss) => {
                selectAllBusiness.push(buss.id)
            })
            setSelectedBusineses(selectAllBusiness)
        }
    }, [getAllOrders])



    useEffect(() => {
        addDriverModal = new window.document.bootstrap.Modal(document.getElementById('addDriver'))
        driverLocationModal = new window.document.bootstrap.Modal(document.getElementById('driverLocation'))

        if (auth.user?.businesses?.length) {
            getDrivers()
            getBusinesses()
            if (getDriverTimeout) {
                clearTimeout(getDriverTimeout)
            }
            // getDriverTimeout = setTimeout(() => fetchData(), 10000)
        }


        return () => {
            if (getDriverTimeout) {
                clearTimeout(getDriverTimeout)
            }
        }
    }, [auth.user])

    useEffect(() => {

        if (editUser) {


            addDriverModal.show()
        }

    }, [editUser])
    useEffect(() => {

        if (driverLocation) {

            console.log("driverLocationModal", driverLocationModal)
            driverLocationModal.show()
        }

    }, [driverLocation])



    return (
        <div className="container">
            <h4>{i18n.t("drivers")}</h4>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{i18n.t("name")}</th>
                        <th scope="col">{i18n.t("email")}</th>
                        <th scope="col">{i18n.t("phone")}</th>
                        <th scope="col">{i18n.t("lastOnline")}</th>
                        <th scope="col">{""}</th>
                    </tr>
                </thead>
                <tbody>
                    {drivers.map((driver, index) => {

                        return <DriverRow key={driver.id} rowNumber={index} driver={driver} onShowDriverLocation={onShowDriverLocation} onShowDriverDetails={onShowDriverDetails} />
                    }
                    )}

                </tbody>
            </table>

            <div className="modal" id="addDriver" tabIndex={-1}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("drivers")}</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal()} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">{i18n.t("username")}</label>
                                <input disabled={editUser} type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} id="exampleFormControlInput1" placeholder="name@example.com" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="emailInput" className="form-label">{i18n.t("email")}</label>
                                <input type="text" disabled={editUser} className="form-control" value={email}
                                    onChange={(e) => setEmail(e.target.value)} id="emailInput" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="nameInput" className="form-label">{i18n.t("name")}</label>
                                <input type="text" className="form-control" value={name}
                                    onChange={(e) => setName(e.target.value)} id="nameInput" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phoneInput" className="form-label">{i18n.t("phone")}</label>
                                <input type="text" className="form-control" value={phone}
                                    onChange={(e) => setPhone(e.target.value)} id="phoneInput" />
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" checked={getAllOrders}
                                    onChange={(e) => setGetAllOrders(!getAllOrders)} type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {i18n.t("get-all-orders")}
                                </label>
                            </div>
                            {!getAllOrders && <select className="form-select" value={selectedBusineses} multiple aria-label="multiple select example"
                                onChange={onSelectedBusinesses}>
                                <option>{i18n.t("select-businesses")}</option>
                                {businesses.map((business, key) => {


                                    return <option key={business.id} value={business.id}>
                                        {business.name}{business.city ? " - " + business.city : null}
                                    </option>
                                })}
                            </select>}
                            <div className="mb-3">
                                <label htmlFor="passwordInput" className="form-label">{i18n.t("password")}</label>
                                <input type="password" className="form-control"
                                    onChange={(e) => setPassword(e.target.value)} id="passwordInput" />
                            </div>

                        </div>


                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={onDelete} data-bs-dismiss="modal">{i18n.t("delete")}</button>
                            <button type="button" className="btn btn-secondary" onClick={closeModal} data-bs-dismiss="modal">{i18n.t("close")}</button>

                            {/* <button type="button" onClick={check} disabled={AddingDriver} className="btn btn-primary">{i18n.t(EditUser ? "save" : "add")}</button> */}
                            <button type="button" onClick={addDriver} disabled={addingDriver} className="btn btn-primary">{i18n.t(editUser ? "save" : "add")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <DriverLocation center={center} closeLocationModal={closeLocationModal} driverLocation={driverLocation} closeModal={closeModal} />
            <style>
                {`
                    .highlight:hover {
                        background-color: #333;
                        color: #fff;
                    }

                    .disabled2 {
                        background-color : #F1F1F1;
                    }

                `}
            </style>

            <button style={{
                position: 'absolute',
                bottom: 20,
                right: 20
            }} className="btn btn-primary" onClick={() => {
                console.log("myModal", addDriverModal)
                addDriverModal.show()

            }} >{i18n.t("add")}</button>

        </div>
    )
}

export default Drivers

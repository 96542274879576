import Axios from 'axios'

const api = Axios.create({
  baseURL: 'https://api.mabali.net'
})
const driverApi = Axios.create({
  baseURL: 'https://driver-api.mabali.net'
})


export const storeJwtToken = (token) => {
  try {
    localStorage.setItem('@jwtToken', token)
  } catch (e) {
    // saving error
  }
}

export const clearJwtToken = () => {
  try {
    localStorage.setItem('@jwtToken', "")
    delete driverApi.defaults.headers["Authorization"]
  } catch (e) {
    // saving error
  }
}

export const getJwtToken = () => {
  try {
    const value = localStorage.getItem('@jwtToken')
    if (value !== null) {
      // value previously stored
      return value
    }
  } catch (e) {
    // error reading value
    return null
  }
}

export const setAuthorization = (jwtToken) => {
  if (jwtToken)
    driverApi.defaults.headers["Authorization"] = "Bearer " + jwtToken
}

//menual.getMedia = 
api.getMedia = (media) => {

  if (!media)
    return " "
  const url = driverApi.defaults.baseURL + media.url
  //console.log("getMedia url = ", url)
  return url;
}

export {
  api,
  driverApi
}